/* ============================================= HeaderContainer ============================================ */

.HeaderContainer p {
  max-width: 500px;
  margin-bottom: 30px;
}

/* ========================================================================================================== */

/* ============================================= ProjectContainer =========================================== */

.ProjectContainer {
  display: flex;
  flex-direction: column;
}
.ProjectContainer .container {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  border-top: var(--b-default);
  padding: 15px 0;
  transition: var(--tr-default);
  z-index: 2;
}
.ProjectContainer .container:first-child {
  border-top: none;
}
.ProjectContainer .container .icon {
  font-size: 50px;
  transition: transform 0.2s ease-in-out;
}
.ProjectContainer .container:hover {
  color: white;
  padding: 15px 30px;
}
.ProjectContainer .container:hover .icon {
  transform: rotate(225deg);
}

.ProjectContainer .container::before {
  background: var(--c-default);
  content: "";
  inset: 0;
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.2s ease-in-out;
  z-index: -1;
}
.ProjectContainer .container:hover::before {
  transform: scaleY(1);
  transform-origin: top;
}

/* ========================================================================================================== */

/* =============================================== ContactForm ============================================== */

.ContactForm {
  max-width: 500px;
}
.ContactForm .AlertSmall {
  margin-bottom: 25px;
  margin-top: 10px;
}
.ContactForm .ctn-input {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 15px 0;
}
.ContactForm .ctn-input textarea {
  min-height: 100px;
}
.ContactForm .text-xxs {
  margin-bottom: 15px;
}
