.ButtonComponent {
  font-family: var(--font-family);
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
  background-color: var(--c-default);
  border: none;
  color: #f7f7f7;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  border-radius: var(--br-button);
  transition-property: background-color;
  transition-duration: 0.2s;
}
.ButtonComponent:hover {
  background-color: var(--c-default-hover);
}
.ButtonComponent .icon.left {
  margin-left: 10px;
}
.ButtonComponent .icon.right {
  margin-right: 10px;
}
.ButtonComponent.success {
  background-color: var(--c-green-bg);
  color: var(--c-green);
  border: 1px solid var(--c-green-border);
}

/* =============================================== SKIN =============================================== */
.ButtonComponent.secondy {
  color: #111;
  border: var(--border-default);
  background: none;
}
.ButtonComponent.secondy:hover {
  background-color: #f7f7f7;
}

.ButtonComponent.white {
  color: #333;
  background-color: #f7f7f7;
}
.ButtonComponent.white:hover {
  background-color: #f7f7f7e0;
}
