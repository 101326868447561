.Image .section .header .sub-title {
  font-weight: 600;
  color: white;
}
.Image .section .header span {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

.Image .wrapper {
  max-width: calc(480.52px * 2 + 30px);
}
.Image .flex-window {
  display: flex;
  align-items: flex-start;
  gap: 1em;
}
.Image .flex-window .window {
  margin: 0;
}
.Image .section.editing {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.Image .section.editing img {
  min-width: 50%;
  max-width: 50%;
  height: 150px;
  object-fit: cover;
  border-radius: var(--br-default);
}
.Image .section.editing .settings {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
.Image .section .ButtonComponent {
  width: 100%;
}

.Image .section.information {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.Image .section.device .exif {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.Image .section.maps .map {
  width: 100%;
  margin-top: 15px;
}
.Image .section.maps .map iframe {
  width: 100%;
  border-radius: var(--br-default);
}
