/* ====================== InputComponents ====================== */

.InputComponents {
  width: 100%;
}
.InputComponents .text-sm {
  font-weight: 600;
  color: var(--c-gray-text);
}
.InputComponents-container {
  cursor: text;
  display: flex;
  align-items: center;
  border-radius: var(--br-button);
  border: var(--b-default);
  padding: 12px 20px;
}
.InputComponents-container.active {
  outline: 2px solid var(--c-default);
  outline-offset: 2px;
}
.InputComponents-container .icon {
  font-size: 18px;
  color: var(--c-gray-bg);
  margin-right: 10px;
}
.InputComponents-container.active .icon {
  color: var(--c-default);
}
.InputComponents-container input,
.InputComponents-container textarea {
  font-family: var(--font-family);
  width: 100%;
  outline: none;
  border: none;
  font-weight: 500;
  background: none;
}
.InputComponents-container input:disabled,
.InputComponents-container textarea:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* ============================================================= */

/* ======================== InputPhones ======================== */

.InputPhones .container {
  padding: 0;
}
.InputPhones .container input {
  padding: 5px 16px;
}
.InputPhones .nb-country {
  display: flex;
  background-color: #fafafb;
  color: #666;
  font-weight: 500;
  padding: 5px 10px;
  border-top-left-radius: var(--br-little);
  border-bottom-left-radius: var(--br-little);
  align-items: center;
}

/* ============================================================= */

/* ======================== SelectInput ======================== */

.SelectInput .InputComponents-container {
  cursor: pointer;
}
.SelectInput select {
  cursor: pointer;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  color: white;
}

/* ============================================================= */
