.Settings .NotificationSettings .list {
  margin-top: 10px;
}
.Settings .NotificationSettings .list .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.Settings .NotificationSettings .list .item p {
  color: white;
}
.Settings .NotificationSettings .list .item .email {
  cursor: not-allowed;
  width: 100%;
  border-radius: var(--br-default);
  padding: 6px 8px;
  margin: 10px 0;
  background-color: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.6);
}
