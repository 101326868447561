.Footer {
  width: 100vw;
  margin-top: 10vh;
  background-color: var(--c-default);
  color: white;
}
.Footer > img {
  width: 100vw;
  margin-bottom: 14.7px;
  transform: rotate(180deg);
}
.Footer .inner {
  display: flex;
  justify-content: space-between;
  padding: 50px 20px;
}
.Footer .inner div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.Footer .inner img {
  height: 60px;
  object-fit: contain;
}
.Footer .inner .text-xs {
  color: rgba(255, 255, 255, 0.8);
}
.Footer .inner .flex {
  display: flex;
  flex-direction: row;
  gap: 80px;
}
.Footer .inner ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Footer .inner ul h5 {
  color: white;
}
.Footer .inner ul button {
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: white;
}
.Footer .inner ul a,
.Footer .inner ul button {
  transition: all 0.2s;
}
.Footer .inner ul a:hover,
.Footer .inner ul button:hover {
  color: rgba(255, 255, 255, 0.8);
}

/* --------- Responsive --------- */

@media screen and (max-width: 800px) {
  .Footer .inner {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 40px;
  }
  .Footer .inner img {
    margin: auto;
  }
  .Footer .inner .flex {
    width: 100%;
    justify-content: space-around;
  }
}

@media screen and (max-width: 520px) {
  .Footer .inner .flex {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
  }
}
