/* nav */
nav {
  position: fixed;
  margin-top: 20px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  height: 85px;
  padding: 0 1rem;
}
nav .wrapper {
  position: relative;
  max-width: calc(64rem - 2rem);
  margin: auto;
  padding: 0.75rem 1rem;
  background: none;
  border-radius: 50px;
  transition: max-width 0.5s;
  z-index: 10;
}
nav .wrapper .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav .flex .left a {
  display: flex;
}
nav .flex .logo-nav {
  height: 28px;
  object-fit: contain;
}
nav .flex > div,
nav .flex div .list {
  display: flex;
  align-items: center;
}
nav .flex div .list .item {
  margin-right: 30px;
  text-decoration: none;
}
nav .flex div .list .item button {
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
nav .flex div .list .item a,
nav .flex div .list .item button {
  text-decoration: none;
  color: white;
  transition: var(--tr-default);
}
nav .flex div .list .item a:hover,
nav .flex div .list .item button:hover {
  color: rgba(255, 255, 255, 0.8);
}
nav .flex div .button {
  margin-right: 0px;
}

/* ----------------------------------------------------------------------------- */

/* ------------------------------ NAV ACTIVE TRUE ------------------------------ */

nav.active-true .wrapper,
nav.responsive-true .wrapper {
  max-width: calc(50rem - 2rem);
  background-color: rgba(242, 242, 242, 0.85);
  backdrop-filter: blur(24px);
}
nav.active-true div .list .item a,
nav.responsive-true div .list .item a,
nav.active-true div .list .item button,
nav.responsive-true div .list .item button {
  color: var(--c-default-text);
}
nav.active-true div .list .item a:hover,
nav.responsive-true div .list .item a:hover,
nav.active-true div .list .item button:hover,
nav.responsive-true div .list .item button:hover {
  color: var(--c-default);
}
nav.active-true .flex .menu-burger span,
nav.responsive-true .flex .menu-burger span {
  background: rgb(0, 0, 0);
}

/* ----------------------------------------------------------------------------- */

/* ------------------------------- NAV RESPONSIVE ------------------------------ */

nav .menu {
  display: none;
}
nav .menu ul {
  margin: 15px;
}
nav .menu ul li {
  list-style: none;
  padding: 10px 0;
  font-size: 22px;
}
nav .menu ul li button {
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: white;
  font-size: 22px;
  transition: var(--tr-default);
}

nav .flex .menu-burger {
  display: none;
  flex-direction: column;
  width: 20px;
  margin-right: 20px;
  cursor: pointer;
}
nav .flex .menu-burger span {
  display: block;
  background: white;
  border-radius: 10px;
  height: 3px;
  margin: 3px 0;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  transition-property: transform, transform-origin;
}
nav .flex .menu-burger span:nth-of-type(1) {
  width: 50%;
}
nav .flex .menu-burger span:nth-of-type(2) {
  width: 100%;
}
nav .flex .menu-burger span:nth-of-type(3) {
  width: 75%;
}
@media screen and (max-width: 768px) {
  nav .flex .menu-burger {
    display: block !important;
  }
  nav .flex .list {
    display: none !important;
  }
}

/* ----------------------------------------------------------------------------- */

/* ---------------------------- NAV RESPONSIVE TRUE ---------------------------- */

nav.responsive-true .wrapper {
  border-radius: 33px;
}
nav.responsive-true .menu {
  display: none;
}
nav.responsive-true .menu {
  display: block;
}
nav.responsive-true .flex .menu-burger {
  display: flex;
}
nav.responsive-true .flex .menu-burger span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotatez(45deg) translate(2px, 0px);
}
nav.responsive-true .flex .menu-burger span:nth-of-type(2) {
  transform-origin: top;
  transform: rotatez(-45deg);
}
nav.responsive-true .flex .menu-burger span:nth-of-type(3) {
  transform-origin: bottom;
  width: 50%;
  transform: translate(9px, -3px) rotatez(45deg);
}

/* ----------------------------------------------------------------------------- */

/* -------------------------------- NAV HOME TRUE ------------------------------ */

nav.home-true .flex .menu-burger span {
  background: black;
}
nav.home-true div .list .item a,
nav.home-true div .list .item button {
  color: var(--c-default-text);
}
nav.home-true div .list .item a:hover,
nav.home-true div .list .item button:hover {
  color: var(--c-default);
}
