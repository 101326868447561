.Settings .AppearanceSettings .header p span {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 3px 12px;
  border-radius: var(--br-default);
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  margin-left: 5px;
  font-size: 14px;
}

.Settings .AppearanceSettings .section .radio {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 15px;
}
.Settings .AppearanceSettings .section .radio .item {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.Settings .AppearanceSettings .section .radio .item.active {
  outline-offset: 2px;
}

.Settings .AppearanceSettings .section .input-bg {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 15px;
}
.Settings .AppearanceSettings .section .input-bg input {
  display: none;
}
.Settings .AppearanceSettings .section .input-bg > img,
.Settings .AppearanceSettings .section .input-bg > label {
  flex: 1;
}
.Settings .AppearanceSettings .section .input-bg .bg {
  object-fit: cover;
  max-width: 205.26px;
  width: 100%;
  height: 100px;
  border-radius: var(--br-default);
  outline: 2px solid var(--c-default);
  outline-offset: 2px;
}
.Settings .AppearanceSettings .section .input-bg .bg[data-image] {
  background-image: attr(data-image);
}
.Settings .AppearanceSettings .section .input-bg .upload {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  height: 100px;
  border-radius: var(--br-default);
  transition: var(--tr-default);
}
.Settings .AppearanceSettings .section .input-bg .upload:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.Settings .AppearanceSettings .section .input-bg .upload .icon {
  font-size: 26px;
}
