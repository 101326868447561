/* ================================ Vishort Window ================================ */

.Vishort .wrapper {
  max-width: calc(480.52px * 2 + 30px);
}
.Vishort .flex-window {
  display: flex;
  align-items: flex-start;
  gap: 1em;
}
.Vishort .flex-window .window {
  margin: 0;
}

.Vishort .section iframe {
  width: 100%;
  height: 200px;
  border-radius: var(--br-default);
}
.Vishort .section .item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 5px 8px;
  margin: 0;
  border-radius: var(--br-default);
}
.Vishort .section .item:hover {
  background-color: var(--c-default);
}
.Vishort .section .item:hover {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Vishort .section .item p {
  color: white;
  text-align: right;
}
.Vishort .section .item .time-code {
  text-align: left;
  text-wrap: nowrap;
}

.Vishort .section .search {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  padding: 8px;
}
.Vishort .section .search .icon {
  min-width: 16px;
  margin-left: 7px;
}
.Vishort .section .search input {
  font-family: var(--ff-default);
  width: 100%;
  border: none;
  background: none;
  outline: none;
  color: white !important;
}
.Vishort .section .search input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.Vishort .section .ctn-search-result span {
  cursor: pointer;
  margin-left: 10px;
  color: white;
  transition: all 0.2s;
}
.Vishort .section .ctn-search-result span:hover {
  color: var(--c-default);
}
