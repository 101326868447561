/* ================================= Settings Nav ================================= */

.Settings .nav {
  width: max-content;
  margin: auto;
}
.Settings .nav ul {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: rgba(27, 27, 27, 0.5);
  backdrop-filter: blur(24px);
  border-radius: 30px;
  padding: 8px;
}
.Settings .nav ul li {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  padding: 8px 12px;
  border-radius: 30px;
}
.Settings .nav ul li:hover {
  color: white;
  transition: color 0.2s;
}
.Settings .nav ul li.active {
  background-color: white;
  color: black;
}
.Settings .nav ul li span {
  user-select: none;
}

/* ================================================================================= */

/* ================================ Settings Window ================================ */

.Settings .window h4 {
  font-size: 20px;
}
.Settings .window p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}
.Settings .section {
  padding: 20px;
  border-top: 1.3px solid rgba(255, 255, 255, 0.2);
}
.Settings .section:first-child {
  border-top: none;
}
.Settings .section .header .sub-title {
  font-weight: 600;
  color: white;
}
.Settings .section .header span {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

.Settings .window .section.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Settings .window .section.buttons .ButtonComponent.secondy {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  padding: 0;
  transition: var(--tr-default);
}
.Settings .window .section.buttons .ButtonComponent.secondy:hover {
  color: white;
  background: none;
}
.Settings .window .section.buttons .ctn-btn {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* ================================================================================= */
