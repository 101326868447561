/* ================================ Short Window ================================ */

.Short .window span {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}
.Short .section .url-history {
  cursor: not-allowed;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  width: calc(100% - 16px);
  border-radius: var(--br-default);
  padding: 6px 8px;
  margin: 10px 0;
  background-color: rgba(255, 255, 255, 0.3);
}
.Short .section .url-history p {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0;
  color: rgba(255, 255, 255, 0.6);
  visibility: visible;
  text-wrap: nowrap;
  overflow: scroll;
}
.Short .section .url-history p::-webkit-scrollbar {
  display: none;
}

.Short .section .url-history p::after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.6);
}

.Short .section .url-history .ButtonComponent:hover + p {
  visibility: hidden;
}

.Short .section .url-history .ButtonComponent:hover + p::after {
  visibility: visible;
}
