.Notification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  transform: translateY(-100%);
  transition: all 0.2s;
}
.Notification.true,
.Notification.false {
  transform: translateY(calc(3.75vh - 19px));
}
.Notification .container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  color: white;
  padding: 10px 20px;
  border-radius: 30px;
  width: auto;
  font-size: 16px;
  font-weight: 500;
}
.Notification.true .container {
  background-color: var(--c-green);
}
.Notification.false .container {
  background-color: var(--c-red);
}
.Notification .container .icon {
  margin-right: 10px;
}
