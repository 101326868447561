/* =================================== Dock =================================== */

.Dock {
  overflow: hidden;
  background-image: var(--bg-dock);
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
}

.Dock .list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  margin: auto;
  margin-top: calc(30vh + 55px);
  gap: 30px;
}
.Dock .list .item {
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 3px;
  background-color: rgba(27, 27, 27, 0.5);
  backdrop-filter: blur(24px);
  box-shadow: var(--bs-default);
  border-radius: var(--br-default);
  transition: var(--tr-default);
}
.Dock .list .item:hover {
  background-color: rgba(27, 27, 27, 0.6);
}
.Dock .list .item > div {
  user-select: none;
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: var(--tr-default);
}
.Dock .list .item:hover div {
  opacity: 0.8;
}

/* ============================================================================ */

/* ================================ Icons Dock ================================ */

.dock-icon-finder {
  background-image: url("../../assets/images/icons/iconFinder.png");
  background-size: cover;
}
.dock-icon-app {
  background-image: url("../../assets/images/icons/iconApp.png");
  background-size: cover;
}
.dock-icon-message {
  background-image: url("../../assets/images/icons/iconMessage.png");
  background-size: cover;
}
.dock-icon-mail {
  background-image: url("../../assets/images/icons/iconMail.png");
  background-size: cover;
}
.dock-icon-outlook {
  background-image: url("../../assets/images/icons/iconOutlook.png");
  background-size: cover;
}
.dock-icon-tel {
  background-image: url("../../assets/images/icons/iconTel.png");
  background-size: cover;
}
.dock-icon-vs {
  background-image: url("../../assets/images/icons/iconVs.png");
  background-size: cover;
}
.dock-icon-terminal {
  background-image: url("../../assets/images/icons/iconTerminal.png");
  background-size: cover;
}
.dock-icon-firefox {
  background-image: url("../../assets/images/icons/iconFirefox.png");
  background-size: cover;
}
.dock-icon-netflix {
  background-image: url("../../assets/images/icons/iconNetflix.png");
  background-size: cover;
}
.dock-icon-notion {
  background-image: url("../../assets/images/icons/iconNotion.png");
  background-size: cover;
}
.dock-icon-vlc {
  background-image: url("../../assets/images/icons/iconVLC.png");
  background-size: cover;
}
.dock-icon-maps {
  background-image: url("../../assets/images/icons/iconMaps.png");
  background-size: cover;
}
.dock-icon-drive {
  background-image: url("../../assets/images/icons/iconDrive.png");
  background-size: cover;
}
.dock-icon-settings {
  background-image: url("../../assets/images/icons/iconSettings.png");
  background-size: cover;
}
.dock-icon-calc {
  background-image: url("../../assets/images/icons/iconCalc.png");
  background-size: cover;
}

/* ============================================================================ */

/* ================================ Pages Dock ================================ */

.PageDock {
  overflow: hidden;
  background-image: var(--bg-dock);
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
}
.PageDock .wrapper {
  max-width: 700px;
  margin: auto;
  margin-top: calc(15vh + 55px);
  gap: 30px;
  animation: PageDock-anim 0.5s ease;
}

@keyframes PageDock-anim {
  0% {
    transform: translateY(17.5vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

.PageDock .window {
  overflow: auto;
  width: 480.52px;
  max-height: 500px;
  margin: auto;
  background-color: rgba(27, 27, 27, 0.5);
  backdrop-filter: blur(24px);
  border-radius: var(--br-default);
  color: white;
  margin-bottom: 15px;
}
.PageDock .window h4 {
  font-size: 20px;
}
.PageDock .window p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}
.PageDock .section {
  padding: 20px;
  border-top: 1.3px solid rgba(255, 255, 255, 0.2);
}
.PageDock .section:first-child {
  border-top: none;
}

/* ============================================================================ */

/* ================================ Pages Nav ================================= */

.PageDock .nav-input {
  display: flex;
  width: 480.52px;
  height: 51px;
  margin: 1em auto;
  background-color: rgba(27, 27, 27, 0.5);
  backdrop-filter: blur(24px);
  border-radius: 30px;
}
.PageDock .nav-input .url-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 16px);
  padding: 8px;
}
.PageDock .nav-input .url-input > div {
  display: flex;
  align-items: center;
  width: 100%;
}
.PageDock .nav-input .url-input .icon {
  font-size: 20px;
  color: #f7f7f7;
  margin-right: 8px;
  margin-left: 10px;
}
.PageDock .nav-input .url-input input {
  font-family: var(--ff-text);
  width: 100%;
  font-size: 18px;
  color: white;
  background: none;
  outline: none;
  border: none;
}
.PageDock .nav-input .url-input input:disabled {
  cursor: not-allowed;
  color: #eee;
}
.PageDock .nav-input .url-input input::placeholder {
  color: #eee;
}

.PageDock .nav-input .url-result {
  display: flex;
  align-items: center;
  color: white;
  padding: 8px 20px;
  border-top: 1.3px solid rgba(255, 255, 255, 0.2);
}
.PageDock .nav-input .url-result .icon {
  font-size: 18px;
  color: #f7f7f7;
  margin-right: 8px;
}
.PageDock .nav-input .url-result p {
  font-size: 18px;
  margin: 0;
}
.PageDock .nav-input .url-result .LoaderComponent {
  margin-left: 5px;
  max-height: 18px;
}

.PageDock .section .InputComponents-container {
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
}
.PageDock .section .InputComponents-container input {
  color: white;
}
.PageDock .section .InputComponents-container input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

/* ============================================================================ */

/* ============================= Pages Nav Image ============================== */

.PageDock .nav-image {
  cursor: pointer;
}
.PageDock .nav-image .url-input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.PageDock .nav-image input {
  display: none;
}
.PageDock .nav-image label {
  cursor: pointer;
  color: white;
}
