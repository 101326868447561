.Togggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.Togggle .ctn-slider {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 23px;
}

/* Hide default HTML checkbox */
.Togggle .ctn-slider input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.Togggle .ctn-slider .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.Togggle .ctn-slider .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3.2px;
  bottom: 2.5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.Togggle .ctn-slider input:checked + .slider {
  background-color: var(--c-default);
}

.Togggle .ctn-slider input:focus + .slider {
  box-shadow: 0 0 1px var(--c-default);
}

.Togggle .ctn-slider input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.Togggle .ctn-slider .slider.round {
  border-radius: 34px;
}

.Togggle .ctn-slider .slider.round:before {
  border-radius: 50%;
}

.Togggle label {
  cursor: pointer;
}
