/* =================================== Qr Window =================================== */

.Qr .window span {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}
.Qr .section {
  display: flex;
  gap: 30px;
}
.Qr .section.header {
  display: block;
}
.Qr .section .flex {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.Qr .section .flex .two-row {
  display: flex;
  gap: 15px;
}
.Qr .section .ctn-qr {
  min-width: 128px;
  max-height: 128px;
}
.Qr .section .qr-code {
  min-width: 128px;
}

.Qr .section .input-color {
  visibility: hidden;
  max-width: 0;
  max-height: 0;
}
.Qr .section .input-color:first-child {
  margin-left: -6px;
}
.Qr .section .input-color:last-child {
  margin-left: 15px;
}
.Qr .section .color-ctn {
  margin-top: -38px;
}
.Qr .section .color-ctn label {
  width: calc(100% - 6.5px);
  height: 45px;
  background-color: blue;
  border-radius: 30px;
}
