.DockBar {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: calc(25rem - 2rem);
  margin: auto;
  margin-top: 20vh;
  background-color: rgba(27, 27, 27, 0.5);
  backdrop-filter: blur(24px);
  border-radius: 50px;
  transition: margin-top 0.5s ease, max-width 0.5s ease;
  box-shadow: var(--bs-default);
  z-index: 10;
}
.DockBar.path {
  margin-top: 7.5vh;
}
.DockBar.active {
  max-width: calc(40rem - 2rem);
}
.DockBar.active.result {
  border-radius: var(--br-default);
}
.DockBar .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 20px);
  padding: 10px 10px;
}
.DockBar .top img.logo {
  width: 80px;
  margin-left: 10px;
}
.DockBar .top .h4 {
  color: white;
  font-size: 28.25px;
  text-wrap: nowrap;
  margin-top: -3px;
  margin-left: 10px;
}
.DockBar .top .search {
  display: none;
  align-items: center;
  width: 100%;
  padding: 0 30px;
}
.DockBar.active .search {
  display: flex;
}
.DockBar .top .search .icon {
  font-size: 20px;
  color: #f7f7f7;
  margin-right: 8px;
}
.DockBar .top .search input {
  font-family: var(--ff-text);
  width: 100%;
  font-size: 18px;
  color: white;
  background: none;
  outline: none;
  border: none;
}
.DockBar .top .search input::placeholder {
  color: #eee;
}
.DockBar .top .account {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.DockBar.active.result .top .account {
  margin-right: 10px;
}

.DockBar .result {
  display: flex;
  flex-direction: column;
  width: calc(100%);
  padding: 5px 0px;
  border-top: 1.3px solid rgba(255, 255, 255, 0.2);
}
.DockBar .result .item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  margin: 0 10px;
  border-radius: var(--br-default);
}
.DockBar .result .item > div {
  display: flex;
  align-items: center;
}
.DockBar .result .item.active,
.DockBar .result .item:hover {
  background-color: var(--c-default);
}
.DockBar .result .item .content {
  display: flex;
  align-items: center;
  width: calc(100% - 35px);
}
.DockBar .result .item div img {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}
.DockBar .result .item div p {
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  color: white;
  font-size: 15px;
  margin: 0;
  transition: max-width 0.5s;
}
.DockBar .result.app .item .content p {
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  color: white;
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  transition: max-width 0.5s;
}
.DockBar .result .item .icon {
  color: white;
  font-size: 18px;
}

.DockBar .result.app .item.active,
.DockBar .result.app .item:hover {
  background: linear-gradient(180deg, var(--c-default), var(--c-default-hover));
}
.DockBar .result.app .item .content div {
  width: 50px;
  height: 50px;
  margin-right: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.DockBar .result .item .button {
  border-radius: 30px;
  font-size: 15px;
  font-weight: 900;
  padding: 5px 12px;
  margin-right: 5px;
  background-color: var(--c-default);
  color: white;
  transition: all 0.2s;
}
.DockBar .result .item.active .button,
.DockBar .result .item:hover .button {
  background-color: white;
  color: var(--c-default);
}
.DockBar .result .item .button:hover {
  background-color: rgb(230, 230, 230);
}
