.Settings .SubscriptionSettings .list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.Settings .SubscriptionSettings .list .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Settings .SubscriptionSettings .buttons {
  justify-content: flex-end !important;
  gap: 20px;
}

.Settings .SubscriptionSettings .button-red .ButtonComponent {
  background-color: var(--c-red);
}
