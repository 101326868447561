.Settings .AccountSettings .list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
}
.Settings .AccountSettings .list .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.Settings .AccountSettings .list .item .InputComponents-container {
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
}
.Settings .AccountSettings .list .item .InputComponents-container input {
  color: white;
}
.Settings
  .AccountSettings
  .list
  .item
  .InputComponents-container
  input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.Settings .AccountSettings .section .input-profile {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
}
.Settings .AccountSettings .section .input-profile input {
  display: none;
}
.Settings .AccountSettings .section .input-profile .bg {
  object-fit: cover;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  outline: 2px solid var(--c-default);
  outline-offset: 2px;
}
.Settings .AccountSettings .section .input-profile .upload {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  transition: var(--tr-default);
}
.Settings .AccountSettings .section .input-profile .upload:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.Settings .AccountSettings .section .input-profile .upload .icon {
  font-size: 22px;
}
