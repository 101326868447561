.Notifications .window .icon-app {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}
.Notifications .window h5 {
  font-size: 16px;
  color: white;
  font-weight: 400 !important;
  margin-bottom: 3px;
}
.Notifications .window p {
  font-size: 16px;
  margin: 0;
  line-height: 18px;
}
.Notifications .window span {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}
.Notifications .section {
  display: flex;
  align-items: center;
  padding: 20px;
  border-top: 1.3px solid rgba(255, 255, 255, 0.2);
}
.Notifications .section:first-child {
  border-top: none;
}
.Notifications .section .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
